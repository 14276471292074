import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import ReactPlayer from "react-player"
import { FaArrowRight } from "react-icons/fa"

const BlackFridayHomeSectionWrapper = styled.div`
  width: 100%;
  position: relative;
  background: #111;
  border-top: ${({ border }) => (border ? "2px solid #fff" : "0px")};

  @media (max-width: 600px) {
    margin: 10px 10px 0px;
    width: calc(100% - 20px);
    border: 0px;
  }
`

const BlackFridayContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  flex-direction: ${({ direction }) => (direction ? "row-reverse" : "row")};
  border-top: ${({ border }) => (border ? "0px" : "2px solid #fff")};

  @media (max-width: 600px) {
    border: 0px;
    flex-direction: column-reverse;
  }
`

const ButtonTitle = styled.span`
  display: none;
`

const LeftSection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;

  @media (max-width: 600px) {
    padding: 30px 20px;
  }
`

const BlackFridayTextWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
`

const TopFlashText = styled.div`
  font-family: "GothamLight";
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2;
  margin-top: 5px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;

  span {
    display: block;
    font-size: 28px;
    line-height: 1.2;
    font-family: "Gotham";
    font-weight: bold;
    color: #fff;
    // background: ${props => props.theme.saleColor.tagGradient};
    background: ${props => props.theme.saleColor.tagBlueGradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media (max-width: 600px) {
      font-size: 24px;
    }
  }

  @media (max-width: 600px) {
    margin-bottom: 5px;
  }
`

const TopText = styled.div`
  font-size: 28px;
  line-height: 1.2;
  font-family: "Gotham";
  font-weight: bold;
  color: #fff;
  background: ${props => props.theme.saleColor.tagGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;

  span {
    @media (max-width: 600px) {
      display: block;
    }
  }

  @media (max-width: 992px) {
    font-size: 24px;
  }

  @media (max-width: 600px) {
    font-size: 18px;
    margin-bottom: 5px;
  }
`

const Title = styled.div`
  font-size: 21px;
  line-height: 1.2;
  font-family: "Gotham";
  font-weight: bold;
  color: #fff;
  text-align: center;

  span {
    @media (max-width: 400px) {
      display: block;
    }
  }
`

const Text = styled.div`
  font-size: 18px;
  line-height: 1.2;
  font-family: "GothamLight";
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin: 20px 0px;

  span {
    display: block;
    font-size: 80px;
    line-height: 1;
    font-family: "BebasNeueBold";
    font-weight: bold;

    @media (max-width: 600px) {
      font-size: 60px;
    }
  }

  @media (max-width: 600px) {
    font-size: 14px;
    margin: 10px 0px;
  }
`

const BottomText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  font-family: "GothamLight";
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin-top: 20px;

  span {
    display: block;
  }

  @media (max-width: 600px) {
    font-size: 12px;
    margin-top: 10px;
  }
`

const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  color: #262626;
  padding: 0;
`

const AbsoluteButton = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
  text-indent: -9999px;
  z-index: 2;
`

const DesktopBannerImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px;
  top: 0px;
  object-fit: cover;

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileBannerImage = styled.img`
  width: 100%;
  margin: 0px;
  top: 0px;
  object-fit: cover;
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`

const DesktopVideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  div {
    position: relative;
  }

  iframe {
    width: 100% !important;
    height: 100% !important;
    margin: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0px;

    @media (max-width: 1200px) {
      width: 110% !important;
      height: 110% !important;
    }

    @media (max-width: 992px) {
      width: 150% !important;
      height: 150% !important;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileVideoWrapper = styled.div`
  width: 100%;
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  iframe {
    width: 140% !important;
    height: 140% !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0px;
    border: 0px;

    @media (max-width: 480px) {
      width: 130% !important;
      height: 130% !important;
    }

    @media (max-width: 420px) {
      width: 112% !important;
      height: 112% !important;
    }

    @media (max-width: 350px) {
      width: 130% !important;
      height: 130% !important;
    }
  }

  @media (max-width: 600px) {
    display: block;
  }
`

const TopSection = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  color: #fff;
  min-height: 400px;

  @media (max-width: 1200px) {
    min-height: 350px;
  }
`

const Button = styled(Link)`
  background: linear-gradient(
    61.74deg,
    #ff7881 -4.05%,
    #ffab61 52.44%,
    #ffe775 91.92%
  );
  padding: 10px 20px;
  color: #111;
  font-family: "Museo";
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  text-align: center;
  margin: 0px;
  border-radius: 25px 0px;

  :hover {
    color: #111;
  }
`

const MedicaleImage = styled.img`
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 100%;
  max-width: 90px;
  margin: 0px;
  z-index: 1;

  @media (max-width: 600px) {
    max-width: 70px;
  }
`

const BlackFridayHomeSection = ({ intl, data, direction, section, border }) => {
  return (
    data && (
      <BlackFridayHomeSectionWrapper border={border}>
        {data.url && (
          <AbsoluteButton to={data.url}>
            <ButtonTitle>{data.url}</ButtonTitle>
          </AbsoluteButton>
        )}
        <BlackFridayContainer direction={direction} border={border}>
          <LeftSection>
            <BlackFridayTextWrapper>
              {section && section != "lyneup" && (
                <TopFlashText
                  dangerouslySetInnerHTML={{
                    __html: "<span>offre flash</span> Jusqu'au mardi 4",
                  }}
                />
              )}
              {data.top_text && (
                <TopText
                  dangerouslySetInnerHTML={{
                    __html: data.top_text,
                  }}
                />
              )}
              {data.title && (
                <Title
                  dangerouslySetInnerHTML={{
                    __html: data.title,
                  }}
                />
              )}
              {data.text && (
                <Text
                  dangerouslySetInnerHTML={{
                    __html: data.text,
                  }}
                />
              )}
              {data.url && (
                <Button to={data.url}>
                  <FaArrowRight
                    style={{ cursor: "pointer" }}
                    color="#111"
                    size={18}
                  />
                </Button>
              )}
              {data.bottom_text && (
                <BottomText
                  dangerouslySetInnerHTML={{
                    __html: data.bottom_text,
                  }}
                />
              )}
            </BlackFridayTextWrapper>
          </LeftSection>
          <RightSection>
            <TopSection>
              {data.image && data.mobile_image && (
                <DesktopBannerImage
                  className="lazyload"
                  data-src={data.image}
                  alt={data.alt ? data.alt : ""}
                />
              )}

              {data.image && data.mobile_image && (
                <MobileBannerImage
                  className="lazyload"
                  data-src={data.mobile_image}
                  alt={data.alt ? data.alt : ""}
                />
              )}

              {section === "lyneup" && (
                <MedicaleImage
                  className="lazyload"
                  data-src="https://static.percko.com/uploads/241a9fdc-70da-4d11-b0c9-af44f8befbb0.png"
                  alt=""
                />
              )}

              {data.video && data.mobile_video && (
                <DesktopVideoWrapper>
                  <ReactPlayer
                    url={data.video}
                    playing={true}
                    controls={false}
                    muted={true}
                    loop={true}
                    width="100%"
                    height="100%"
                    playsinline={true}
                    playsInline
                  />
                </DesktopVideoWrapper>
              )}

              {data.video && data.mobile_video && (
                <MobileVideoWrapper>
                  <ReactPlayer
                    url={data.mobile_video}
                    playing={true}
                    controls={false}
                    muted={true}
                    loop={true}
                    width="100%"
                    height="100%"
                    playsinline={true}
                    playsInline
                  />
                </MobileVideoWrapper>
              )}
            </TopSection>
          </RightSection>
        </BlackFridayContainer>
      </BlackFridayHomeSectionWrapper>
    )
  )
}

export default injectIntl(BlackFridayHomeSection)
